body {
  margin: 0;
  background-color: #f5f5f5;
}

.navbar {
  background-color: var();
}

.navbar a {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  padding: 0 24px !important;
}

.aboutUs-section,
.ourProduct-section,
.healthy-section,
.ourCustomers-section,
.ourGallery-section,
.footer-section {
  padding: 96px 0 36px;
}

h2 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 35px;
  line-height: 52px;
  color: #000000;
  margin-bottom: 40px;
  text-align: center;
}

.margin-8 {
  margin-bottom: 8px;
}

.containers {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
}

.containers .slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 500%;
  height: 100%;
  animation: animate 20s linear infinite;
}

/* @keyframes animate {
  0%,
  18% {
    left: 0%;
  }
  20% {
    left: -100%;
  }
  38% {
    left: -100%;
  }
  40% {
    left: -200%;
  }
  58% {
    left: -200%;
  }
  60% {
    left: -300%;
  }
  78% {
    left: -300%;
  }
  80%,
  98% {
    left: -400%;
  }
} */

@keyframes animate {
  0% {
    left: 0%;
  }
  20% {
    left: 0%;
  }
  25% {
    left: -100%;
  }
  45% {
    left: -100%;
  }
  50% {
    left: -200%;
  }
  70% {
    left: -200%;
  }
  75% {
    left: -300%;
  }
  95% {
    left: -300%;
  }
  100% {
    left: -400%;
  }
}

.containers .slider .slide {
  width: 20%;
  height: 100%;
  float: left;
  padding: 4% 1%;
}

.containers .slider .slide.slide1 {
  background: url("../src/Images/img1.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.containers .slider .slide.slide2 {
  background: url("../src/Images/img2.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.containers .slider .slide.slide3 {
  background: url("../src/Images/img3.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.containers .slider .slide.slide4 {
  background: url("../src/Images/img1.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.containers .slider .slide.slide5 {
  background: url("../src/Images/img2.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.landingSection-header {
  display: flex;
  justify-content: end;
  position: relative;
  right: 0%;
}

.slide.slide3 .landingSection-header {
  float: left;
}

.containers h1 {
  font-family: "Poppins";
  font-weight: 900;
  font-size: 80px;
  color: #ffffff;
}

.containers span {
  font-weight: 500;
  color: #ffc700;
}

/* aboutUs-section */

.aboutUs-section img {
  width: 100%;
  margin-bottom: 24px;
}

.aboutUs-section p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  text-align: justify;
  color: #000000;
}

/* ourProduct-section */

.ourProduct-section-card {
  background: #ffffff;
  padding: 22px;
  border: 1px solid #dfdfdf;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 40px;
  min-height: 492px;
}

.ourProduct-section-card h4 {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 24px;
  line-height: 45px;
  color: #363636;
  margin-bottom: 4px;
  margin-top: 10px;
}

.ourProduct-section-card p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

/* healthy-section */

.healthy-section h2 {
  margin-bottom: 12px !important;
}

.healthy-section p {
  max-width: 740px;
  margin: auto;
  margin-bottom: 40px;
  text-align: center;
  margin-bottom: 64px;
}

.healthy-section .row p {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 23px;
  line-height: 34px;
  color: #000000;
  margin-top: 20px;
}

/* OurCustormers */

.ourCustomers-section-bg {
  background: linear-gradient(rgba(0, 0, 0, 0.5) 100%, rgba(0, 0, 0, 0.5) 100%),
    url("/src/Images/landing-section-bg.png");
  padding: 62px 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.custormers-review-container {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  max-width: 724px;
  margin: auto;
  padding: 30px 116px;
  text-align: center;
}

.slick-dots li button:before {
  font-size: 16px !important;
  color: #d9d9d9 !important;
  opacity: 1 !important;
  margin-top: 16px;
}
.react-multi-carousel-dot button {
  width: 16px !important;
  height: 16px !important;
  border: none !important;
  background: #d9d9d9 !important;
}
.react-multi-carousel-dot--active button {
  background-color: #ffc700 !important;
}

.slick-list {
  text-align: center !important;
}

.slick-next:before {
  display: none;
}

.slick-prev:before {
  display: none;
}

.slick-dots li.slick-active button:before {
  color: #ffc700 !important;
  opacity: 1 !important;
}

/* footer-section */

.footer-section {
  background-color: #ffc700;
}

.footer-section a {
  display: block;
  text-decoration: none;
  margin-bottom: 16px;
  font-family: "poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.footer-section h4 {
  margin-bottom: 12px;
}

.footer-section p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.slick-next {
  right: 0 !important;
}

.ourGallery-section .row {
  max-width: 1080px;
  margin: auto;
  margin-bottom: 8px;
}

.height238 {
  height: 238px;
}

.mb-8px {
  margin-bottom: 8px;
}

.fixedWhatsapp {
  position: fixed;
  right: 32px;
  bottom: 32px;
  z-index: 999;
}

.fixedWhatsapp img {
  height: 44px;
}

@media only screen and (max-width: 600px) {
  h2 {
    font-size: 18px !important;
    line-height: 27px !important;
    margin-bottom: 11px !important;
  }

  .landingSection-header h1 {
    font-size: 44px;
    line-height: 52px;
  }

  .custormers-review-container {
    padding: 30px;
  }

  .aboutUs-section,
  .footer-section,
  .healthy-section,
  .ourCustomers-section,
  .ourGallery-section,
  .ourProduct-section {
    padding: 40px 0 8px !important;
  }

  h4 {
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;
  }

  p {
    font-size: 14px !important;
    line-height: 21px !important;
  }

  .ourGallery-section img:first-child {
    margin-bottom: 8px;
  }
  .height238 {
    height: fit-content;
  }
  .ourProduct-section-card {
    min-height: fit-content !important;
  }
  .navbar a {
    padding: 8px 4px !important;
  }

  .slide1,
  .slide2,
  .slide3,
  .slide4,
  .slide5 {
    background-position: center !important;
  }

  .containers .slider .slide {
    padding: 16% 1% !important;
  }

  .mt20 {
    margin-top: 20px;
  }

  .ml20 {
    padding-left: 20px;
  }
}
